<template>
	<div style="margin:-30px; margin-bottom: 30px; padding: 40px; background-color: #FBF7F4; height: 170px; text-align:center"><img style="height: 100%" :src="require('@/assets/Whatsapp.webp')" /></div>
	<CustomerLoadWait :customer="customer" />
	<div class="test-page" v-if="customer">
		<h2>WhatsApp AI Coach</h2>
		<h1>Support with your finances</h1>
		<br/>
		<BulletItem :bullet-image="require('@/assets/bullet-question.png')" caption="Your finance questions answered"></BulletItem>
		<BulletItem :bullet-image="require('@/assets/bullet-doc.png')" caption="Quickly add policies to your account - simply take a picture or upload a PDF"></BulletItem>
		<BulletItem :bullet-image="require('@/assets/bullet-bell.png')" caption="Get reminders when one of your policies is about to expire"></BulletItem>
		<BulletItem :bullet-image="require('@/assets/bullet-tick.png')" caption="And much more!"></BulletItem>
		<p><v-btn @click="enableWhatsApp()" style="background-color: #1dcd45 !important">{{buttonText}}</v-btn></p>
		<p v-if="done"><v-btn @click="this.$router.back()">Done</v-btn></p>
		<v-bottom-sheet v-model="sheet">
			<v-card style="padding: 40px; border-radius: 10px 10px 0px 0px; height: 200px">
				<p style="text-align: center">We have sent you a WhatsApp message.<br/>Please click "I'm In".</p>
			</v-card>
		</v-bottom-sheet>
	</div>
</template>

<script>
import Services from '../mixins/Services';
import BulletItem from './elements/BulletItem.vue';
import CustomerLoadWait from './elements/CustomerLoadWait.vue';

export default {
	mixins: [Services],
	components: {CustomerLoadWait, BulletItem},
	data() {
		return {
			sheet:false,
			buttonText:"Enable WhatsApp",
			done: false
		}
	},
	methods: {
		getCustomer: function(){
			this._getCustomer(() => {
				this._getFeatures();
				//this._enableWhatsApp();
			});
		},
		enableWhatsApp: function() {
			this._enableWhatsApp();
			this.sheet = true;
			this.buttonText = "Resend message"
			this.done = true;
		},
		onLoad: async function() {
			this.getCustomer();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

h1 {
	font-size: 23px;
}

</style>
