import { createApp } from 'vue';
import VueCookies from 'vue-cookies';
import { createRouter, createWebHashHistory } from 'vue-router';
import axios from 'axios';

import App from './App.vue'
import Notifications from './components/NotificationsPage.vue'
import WhatsAppCoaching from './components/WhatsAppCoachingPage.vue'
import MoneyHubLink from './components/MoneyHubLinkPage.vue'
import MoneyHubCallback from './components/MoneyHubCallbackPage.vue'
import YouPage from './components/YouPage.vue'
import CompletedMovesPage from './components/CompletedMovesPage.vue'

// Vuetify
import 'vuetify/styles'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import EnableWhatsApp from './components/EnableWhatsApp.vue';

const vuetify = createVuetify({
  components,
  directives,
  icons: {
		defaultSet: 'mdi',
		aliases,
		sets: {
			mdi,
		},
	},
})

var api = "https://api.moneyguided.com";
var configUri = "dev";

const router = createRouter({
	history: createWebHashHistory(),
	routes: [
		{
			path: '/', component: Notifications,
			meta: {
				hideNavbar: true,
				hideFooter: true,
				whatsapp: true,
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/notifications', component: Notifications,
			meta: {
				hideNavbar: true,
				hideFooter: true,
				whatsapp: true,
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/whatsappcoaching', component: WhatsAppCoaching,
			meta: {
				hideNavbar: true,
				hideFooter: true,
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/moneyhublink', component: MoneyHubLink,
			meta: {
				hideNavbar: true,
				hideFooter: true,
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/moneyhubcb/:code', component: MoneyHubCallback,
			meta: {
				hideNavbar: true,
				hideFooter: true,
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/you', component: YouPage,
			meta: {
				hideNavbar: true,
				hideFooter: true,
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/completedmoves', component: CompletedMovesPage,
			meta: {
				hideNavbar: true,
				hideFooter: true,
				api: api,
				configUri: configUri
			}
		},
		{
			path: '/enablewhatsapp', component: EnableWhatsApp,
			meta: {
				hideNavbar: true,
				hideFooter: true,
				api: api,
				configUri: configUri
			}
		},
	]
});

const app = createApp(App);
app.config.globalProperties.$http = axios;
app.use(VueCookies);
app.use(router);
app.use(vuetify);
app.mount("#app");
