<template>
	<a :href="href != null ? href : $router.path">
		<div class="ad-button" :style="'background-image:url(' + bottomrighticon + ')'">
			<v-row>
				<v-col class="icon-col v-col-1">
					<img :src="topicon"/>
				</v-col>
				<v-col class="title-col">
					<div class="title">{{ title }}</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col class="subtitle-col v-col-9">
					<div class="sub-title">{{ subtitle }}</div>
				</v-col>
			</v-row>
		</div>
	</a>
</template>

<script>
export default {
	name: "AddButton",
	props: ['topicon', 'bottomrighticon', 'title', 'subtitle', 'href'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.ad-button {
	border-radius: 8px;
	padding: 18px;
	border-color: gainsboro;
	border: 1px solid gainsboro;
	height: 100%;
	background-color: white;
}

.title {
	font-size: 16px;
}

.sub-title {
	font-size: 12px;
}

.icon-col {
	
}

.icon-col img {
	height: 18px;
	margin-top: 3px;
}

.value-title {
	font-size: 10px;
}

.value {
	font-size: 16px;
}

.right-col {
	text-align: center;
}

.ad-button {
	background-position: right bottom;
	background-size: 70px;
}

</style>