<template>
	<div class="centered">
		<img class="pageHeaderIconBigger" src="@/assets/LinkIcon.svg"/>
	</div>
	<p v-if="!customer">Authenticating . . .</p>
	<div class="test-page" v-if="customer">
		<h2>Why Link Your Investment Accounts?</h2>
		<p>It's safe and secure.  Money Guided has the same security standards as banks.</p>
		<p>See all your balances in one place.</p>
		<p>Receive money-saving guidance based on your financial situation.</p>
		<h2 class="mt-4">Money Guided:</h2>
		<p>Never see or store your login details.</p>
		<p>Uses 256-bit encryption to connect to your financial institution.</p>
		<p><small>Money Guided is registered with the Financial Conduct Authority under the Payment Services Regulations 2017 for the provision of payment services.</small></p>
		<p><v-btn v-if="linkUrl" @click="linkAccount">Proceed</v-btn></p>
	</div>
</template>

<script>
import Services from '../mixins/Services';
export default {
	mixins: [Services],
	data() {
		return {
			linkUrl: null
		}
	},
	methods: {
		linkAccount: function(){
			window.location.href=this.linkUrl;
		},  
		getCustomer: function(){
			this._getCustomer(() => {
				this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/OpenFinance/LinkUrl")
						.then((result) => {
							this.linkUrl = result.data;
						}
					)
			});
		},
		onLoad: async function() {
			this.getCustomer();
		}
	},
	mounted: function () {
		this.onLoad();
	},
}
</script>

<style scoped>

h2 {
	font-size: 15px;
}
p {
	font-size: 12px;
}

</style>
