export default {

	data() {
		return{
			api: null,
			configUri: null,
			customer: null,
			employer: null,
			disabledFeatures: [],
			enabledFeatures: [],
			products: [],
			benefits: [],
			config: null,
			token: null,
			completedMoves: null
		}
	},

	created: function() {
		this.api = this.$route.meta.api;
		this.configUri = this.$route.meta.configUri;
		this.token = this.$route.query.t;
		this.$http.defaults.headers.common = { "Authorization": "bearer " + this.token};
	},

	methods: {

		_enableWhatsApp: function () {
			var request = {
				"title": "welcome_to_mg",
				"whatsApp": true,
				"whatsAppTemplate": true,
				"phoneNumber": this.customer.person.mobilePhone
			};
			this.$http.post(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Notifications", request)
				.then(() => {
					}
				);
		},

		_getAppConfig: function(onSuccess = null) {
			this.$http.get("https://s3.eu-west-2.amazonaws.com/config.moneyguided.com/" + this.configUri + "_config.json")
				.then((result) => {
					this.config = result.data;
					if (onSuccess) {
						onSuccess();
					}
				});
		},

		_getCustomer: function(onSuccess = null) {
			if (!this.customer) {
				this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000?getFacts=true")
				.then((result) => {
					this.customer = result.data;
					if (onSuccess) {
						onSuccess();
					}
				});
			} else {
				if (onSuccess) {
					onSuccess();
				}
			}
		},

		_getEmployer: function(employerId, onSuccess = null) {
			if (!this.employer) {
				this.$http.get(this.api + "/Employers/" + employerId)
				.then((result) => {
					this.employer = result.data;
					if (onSuccess) {
						onSuccess();
					}
				});
			} else {
				if (onSuccess) {
					onSuccess();
				}
			}
		},

		_getFeatures: function(onSuccess = null) {
			this._getEmployer(this.customer.employerID, () => {
				this._parseFeatures();
				if (onSuccess) {
					onSuccess();
				}
			});
		},

		_parseFeatures: function() {
			this.disabledFeatures = [];
			this.enabledFeatures = [];
			for (var x = 0; x < this.employer.features.length; x++) {
				if (!this.employer.features[x].enabled) {
					this.disabledFeatures.push(this.employer.features[x].feature.name);
				} else {
					this.enabledFeatures.push(this.employer.features[x].feature.name);
				}
			}
			console.log(this.disabledFeatures);
			console.log(this.enabledFeatures);
		},

		_getProducts: function(employerId, onSuccess = null) {
			this.products = [];
			this.benefits = [];
			this.$http.get(this.api + "/Products/?employerId=" + employerId)
				.then((result) => {
					this.products = result.data;
					for (var x = 0; x < this.products.length; x++) {
						if (this.products[x].thumbnail.startsWith("Logos/")) {
							this.products[x].thumbnail = this.products[x].thumbnail.replace('Logos/', 'https://s3.eu-west-2.amazonaws.com/content.sysodev.link/Logos/')
						}
						this.products[x].img = this.products[x].thumbnail;
						if (this.products[x].tags == "employeeBenefit") {
							this.benefits.push(this.products[x]);
						}
					}
					console.log(this.products);
					console.log(this.benefits);
					if (onSuccess) {
						onSuccess();
					}
				});
		},

		_getCompletedMoves: function() {
			this.$http.get(this.api + "/Customers/00000000-0000-0000-0000-000000000000/Moves?filterByActioned=true")
				.then((result) => {
					this.completedMoves = result.data;
				}
			);
		},

	}
  }