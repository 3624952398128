<template>
	<a :href="href != null ? href : $router.path">
		<div :class="'scroll-thumb-block ' + (blur ? 'blur' : '')">
			<v-row>
				
				<v-col class="title-col">
					<div class="title">{{ title }}</div>
					<div class="sub-title">{{ subtitle }}</div>
				</v-col>
				<v-col class="rightimage-col v-col-2" >
					<img :src="rightimage">
				</v-col>
			</v-row>
			<v-row>
				<v-slide-group>
					<div class="thumb" v-for="(t, idx) in thumbs" :key="idx" :style="'background-image: url(' + t.img + ')'" />
				</v-slide-group>
			</v-row>
		</div>
	</a>
</template>

<script>
export default {
	name: "ScrollThumbBlock",
	props: ['title', 'subtitle', 'thumbs', 'rightimage', 'blur', 'href'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

.scroll-thumb-block {
	border-radius: 8px;
	padding: 18px;
	border: 1px solid gainsboro;
	background-color: white;
}

.title {
	font-size: 16px;
}

.sub-title {
	font-size: 12px;
}

.thumb {
	height: 60px;
	width: 60px;
	margin: 8px;
	border-radius: 8px;
	border: 1px solid gainsboro;
	display: inline-block;
	background-position: center;
	background-size: cover;
}

.rightimage-col {
	text-align: right;
}

.rightimage-col img {
	height: 20px;
}

</style>