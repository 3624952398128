<template>
	<div :class="'move-panel'">
		<v-row>
			<v-col class="icon-col">
				<img :src="move.thumbnail"/>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="title-col">
				<div class="title">{{ move.title }}</div>
				<div class="tag-line">{{ move.tagLine }}</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col><v-chip variant="outlined" size="small">Completed</v-chip> <v-chip class="ml-1 feature-pill" variant="outlined" size="small">{{ move.featureTags }}</v-chip></v-col>
		</v-row>
		<v-row>
			<v-col>
				<div class="description">{{ customerMove.description }}</div>
			</v-col>
		</v-row>
		<v-row class="bottom-panel mt-5" style="padding-top: 10px;">
			<v-col class="v-col-1">
				<img :src="require('@/assets/clock.png')">
			</v-col>
			<v-col>
				{{ move.timeToComplete }} minutes
			</v-col>
		</v-row>
	</div>
</template>

<script>
export default {
	name: "InfoPanel",
	props: ['move', 'customerMove'],
	data() {
		return{
			
		}
	},
	methods: {
		onLoad() {
		}
	},
	mounted: function() { this.onLoad(); }
}
</script>

<style scoped>

body {
	background-color: #FBF7F4 !important;
}

.move-panel {
	border-radius: 8px;
	padding: 18px;
	border-color: gainsboro;
	border: 1px solid gainsboro;
	background-color: white;
}

.title {
	font-size: 16px;
}

.tag-line {
	font-size: 14px;
	opacity: 0.5;
}

.icon-col {
	padding-bottom:0px;
}

.icon-col img {
	height: 40px;
}

.description {
	font-size: 13px;
	font-weight: normal;
}

.feature-pill {
	background: linear-gradient(243.52deg, #FCE5D4 18.85%, #E0C5C8 81.37%);
	border:1px;
}

.bottom-panel {
	border-top: 1px solid gainsboro;
}

.v-align-middle {
	vertical-align: text-top !important;
}

</style>