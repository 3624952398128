<template>
  <div id="app">

		<div id="outer">
			<router-view></router-view>
		</div>
	
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style>

@font-face {
  font-family: 'Gotham';
  src: url('assets/Gotham-Medium.ttf');
}

body {
  background-color:#FBF7F4;
  color: #281C3B;
  font-size: 12px;
  margin: 30px;
  margin-top: 10px;
}

h1,h2 {
  font-size: 15px;
  margin-bottom: 8px;
  font-weight: lighter;
}

p {
  margin-bottom: 9px;
  font-weight: lighter;
}

.middle-page {
  position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%)
}

.middle-page-topthird {
  position: absolute; top: 30%; left: 50%; transform: translate(-50%, -50%)
}

.v-label {
  font-size: small !important;
  color: #281C3B !important;
  opacity: 100 !important;
  font-size: 12px !important;
}

.pageHeaderIcon {
  width: 40px;
  margin-bottom: 20px;
  /* margin-right:auto;
  margin-left:auto;
  display: block; */
}

.pageHeaderIconBigger {
  width: 80px;
  margin-bottom: 20px;
  /* margin-right:auto;
  margin-left:auto;
  display: block; */
}

.v-btn {
    display: block !important;;
    border-radius: 30px !important;
    height: 50px !important;
    background-color: #281C3Bff !important;
    color: white !important;
    text-decoration: none !important;
    text-transform: capitalize !important;
    padding-right: 30px !important;
    padding-left: 30px !important;
    margin-top: 10px;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 80%;
    margin-top: 30px;
}
.centered {
  text-align: center;
}

* { font-family: 'Gotham' }

.blur {
	opacity: 0.7;
	filter: blur(1.5px);
}

a {
  text-decoration: none;
  color: unset;
}

</style>
